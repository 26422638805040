var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "div",
        { staticClass: "kt-portlet", class: { "mb-0": _vm.createAnecdotal } },
        [
          _c(
            "div",
            {
              staticClass: "kt-portlet__head",
              on: { mousedown: _vm.dragMouseDown },
            },
            [
              _c("div", { staticClass: "kt-portlet__head-label" }, [
                _vm.createAnecdotal
                  ? _c("h3", { staticClass: "kt-portlet__head-title" }, [
                      _vm._v(" Create Anecdotal Event "),
                    ])
                  : _c("h3", { staticClass: "kt-portlet__head-title" }, [
                      _vm._v(" Edit Anecdotal Event "),
                    ]),
              ]),
              _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                _vm.toggleSize
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-sm btn-icon btn-clean btn-icon-md",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.toggleSize.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("i", {
                          class: [
                            "la",
                            _vm.minimized ? "la-angle-up" : "la-angle-down",
                          ],
                        }),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-icon",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.closeModal.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "la la-close" })]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { class: [_vm.minimized ? "d-none" : ""] },
            [
              _vm.anecdotalEventCategories.length == 0
                ? [_vm._m(0)]
                : [
                    _c("div", { staticClass: "kt-portlet__body" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-lg-7" },
                          [
                            _c(
                              "div",
                              { staticClass: "w-100" },
                              [
                                _vm._m(1),
                                _c("AnecdotalCategoryPicker", {
                                  attrs: {
                                    "current-category": _vm.anecdotal
                                      .anecdotalEventCategoryId
                                      ? _vm.anecdotal
                                      : null,
                                    "on-selected": _vm.categorySelected,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "w-100 row pt-4" }, [
                              _c(
                                "label",
                                { staticClass: "col-9 col-form-label" },
                                [_vm._v("Current Date and Time")]
                              ),
                              _c("div", { staticClass: "col-3" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "kt-switch kt-switch--success",
                                  },
                                  [
                                    _c("label", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.currentDateTime,
                                            expression: "currentDateTime",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.currentDateTime
                                          )
                                            ? _vm._i(
                                                _vm.currentDateTime,
                                                null
                                              ) > -1
                                            : _vm.currentDateTime,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.currentDateTime,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.currentDateTime =
                                                    $$a.concat([$$v]))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.currentDateTime = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.currentDateTime = $$c
                                            }
                                          },
                                        },
                                      }),
                                      _c("span"),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c(
                              "b-collapse",
                              {
                                model: {
                                  value: _vm.showDateTime,
                                  callback: function ($$v) {
                                    _vm.showDateTime = $$v
                                  },
                                  expression: "showDateTime",
                                },
                              },
                              [
                                _c("div", { staticClass: "w-100 pt-3 row" }, [
                                  _c("div", { staticClass: "col-6" }, [
                                    _c("label", {}, [
                                      _c(
                                        "span",
                                        { staticClass: "kt-font-danger" },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v(" Date"),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "input-group date" },
                                      [
                                        _c("b-form-datepicker", {
                                          staticClass: "form-control kt-input",
                                          attrs: {
                                            "date-format-options": {
                                              year: "numeric",
                                              month: "numeric",
                                              day: "numeric",
                                            },
                                            "reset-button": "",
                                            "drop-bottom": "",
                                          },
                                          model: {
                                            value:
                                              _vm.anecdotal.anecdotalEventDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.anecdotal,
                                                "anecdotalEventDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "anecdotal.anecdotalEventDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-6" }, [
                                    _c("label", {}, [_vm._v("Time")]),
                                    _c(
                                      "div",
                                      { staticClass: "input-group date" },
                                      [
                                        _c("b-form-timepicker", {
                                          staticClass: "form-control kt-input",
                                          attrs: {
                                            placeholder: "",
                                            "reset-button": "",
                                            "now-button": "",
                                          },
                                          model: {
                                            value:
                                              _vm.anecdotal.anecdotalEventTime,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.anecdotal,
                                                "anecdotalEventTime",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "anecdotal.anecdotalEventTime",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-lg-5" },
                          [
                            _c(
                              "label",
                              { attrs: { title: "Add students or teachers" } },
                              [
                                _c("span", { staticClass: "kt-font-danger" }, [
                                  _vm._v("*"),
                                ]),
                                _vm._v(" Participants "),
                                _vm.participants.length > 0
                                  ? _c(
                                      "code",
                                      { staticClass: "ml-4 kt-font-success" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.participants.length) +
                                            " added "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _c("AnecdotalUserPicker", {
                              attrs: { "on-selected": _vm.userAdded },
                            }),
                            _c(
                              "div",
                              { staticClass: "tab-content student-list p-3" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "kt-widget4" },
                                  _vm._l(
                                    _vm.participants,
                                    function (user, idx) {
                                      return _c(
                                        "div",
                                        {
                                          key: `${idx}_anec_portfolio`,
                                          staticClass: "kt-widget4__item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-widget4__pic kt-widget4__pic--pic",
                                            },
                                            [
                                              _c(
                                                "UserAvatar",
                                                {
                                                  attrs: {
                                                    "avatar-user": user,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kt-badge kt-badge--lg kt-badge--success",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            user.lastName.substring(
                                                              0,
                                                              1
                                                            )
                                                          ) +
                                                          _vm._s(
                                                            user.firstName.substring(
                                                              0,
                                                              1
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "kt-widget4__info" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "kt-widget4__username",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.removeUserAtIndex(
                                                        idx
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(user.lastName) +
                                                      ", " +
                                                      _vm._s(user.firstName) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              user.portfolioType == "Student"
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "kt-widget4__text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            user.extStudentId.substring(
                                                              0,
                                                              3
                                                            )
                                                          ) +
                                                          "-" +
                                                          _vm._s(
                                                            user.extStudentId.substring(
                                                              3,
                                                              6
                                                            )
                                                          ) +
                                                          "-" +
                                                          _vm._s(
                                                            user.extStudentId.substring(
                                                              6,
                                                              9
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              user.portfolioType == "Teacher"
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "kt-widget4__text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            user.schoolEmail
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.removeUserAtIndex(
                                                    idx
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "la la-close",
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "row pt-3" }, [
                        _c(
                          "div",
                          { staticClass: "col-lg-12" },
                          [
                            _c("label", [_vm._v("Anecdotal Description")]),
                            _c("v-md-editor", {
                              staticClass: "v-md-editor",
                              attrs: {
                                height: "200px",
                                "right-toolbar": _vm.rightToolbar,
                                "left-toolbar": _vm.leftToolbar,
                                mode: "edit",
                              },
                              on: {
                                change: function ($event) {
                                  _vm.anecdotal.anecdotalEventDescriptionEdited = true
                                },
                              },
                              model: {
                                value: _vm.anecdotal.anecdotalEventDescription,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.anecdotal,
                                    "anecdotalEventDescription",
                                    $$v
                                  )
                                },
                                expression:
                                  "anecdotal.anecdotalEventDescription",
                              },
                            }),
                            _vm._m(2),
                          ],
                          1
                        ),
                        !_vm.createAnecdotal &&
                        _vm.anecdotal.anecdotalEventDescription
                          ? _c(
                              "div",
                              { staticClass: "col-lg-12 pt-5" },
                              [
                                _c("label", { staticClass: "pb-3" }, [
                                  _vm._v("Anecdotal Description Preview"),
                                ]),
                                _c("VueShowdown", {
                                  attrs: {
                                    markdown:
                                      _vm.anecdotal.anecdotalEventDescription,
                                    flavor: "github",
                                    options: { emoji: true },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "kt-portlet__foot row pt-4 pb-4 pl-5 pr-5",
                        class: [_vm.minimized ? "d-none" : ""],
                      },
                      [
                        _c("div", { staticClass: "col-lg-4" }, [
                          _c("div", { staticClass: "mt-1" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-brand btn-bold",
                                class: {
                                  "kt-spinner kt-spinner--sm kt-spinner--light":
                                    _vm.saving,
                                },
                                attrs: { type: "button", disabled: _vm.saving },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.saveAnecdotal.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v(" Save Anecdotal ")]
                            ),
                            _vm.editAnecdotalEventId && _vm.canDeleteAnecdotal
                              ? _c("span", [
                                  _vm.anecdotal.deleted
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-label-success btn-sm btn-bold ml-3",
                                          attrs: {
                                            type: "button",
                                            disabled:
                                              _vm.saving || _vm.deleting,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.deleteAnecdotal(false)
                                            },
                                          },
                                        },
                                        [_vm._v(" Restore ")]
                                      )
                                    : _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-label-danger btn-sm btn-bold ml-3",
                                          attrs: {
                                            type: "button",
                                            disabled:
                                              _vm.saving || _vm.deleting,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.deleteAnecdotal(true)
                                            },
                                          },
                                        },
                                        [_vm._v(" Delete ")]
                                      ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            class: {
                              "col-lg-8": _vm.anecdotal.isConfidential,
                              "col-lg-2": !_vm.anecdotal.isConfidential,
                            },
                          },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "kt-checkbox kt-checkbox--tick kt-checkbox--danger mt-3",
                                attrs: {
                                  title:
                                    "Confidential anecdotals are only visible to you and will not be shared with other users.",
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.anecdotal.isConfidential,
                                      expression: "anecdotal.isConfidential",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.anecdotal.isConfidential
                                    )
                                      ? _vm._i(
                                          _vm.anecdotal.isConfidential,
                                          null
                                        ) > -1
                                      : _vm.anecdotal.isConfidential,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.anecdotal.isConfidential,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.anecdotal,
                                              "isConfidential",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.anecdotal,
                                              "isConfidential",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.anecdotal,
                                          "isConfidential",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                !_vm.anecdotal.isConfidential
                                  ? [_vm._v(" Confidential ")]
                                  : [
                                      _c(
                                        "em",
                                        {
                                          staticClass:
                                            "text-muted kt-font-danger",
                                        },
                                        [
                                          _vm._v(
                                            " Only you will be able to view this anecdotal. "
                                          ),
                                        ]
                                      ),
                                    ],
                                _c("span"),
                              ],
                              2
                            ),
                          ]
                        ),
                        !_vm.anecdotal.isConfidential
                          ? _c("div", { staticClass: "col-lg-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group mb-0 pull-right" },
                                [
                                  _c("label", [_vm._v("Share With:")]),
                                  _c(
                                    "div",
                                    { staticClass: "kt-checkbox-inline" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          staticClass: "kt-checkbox",
                                          attrs: {
                                            title: _vm.anecdotal
                                              .studentShareDisabled
                                              ? `Student sharing is ${
                                                  _vm.anecdotal.visibleStudent
                                                    ? "always"
                                                    : "never"
                                                } enabled for this category`
                                              : "Share with students",
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.anecdotal.visibleStudent,
                                                expression:
                                                  "anecdotal.visibleStudent",
                                              },
                                            ],
                                            attrs: {
                                              disabled:
                                                _vm.anecdotal
                                                  .studentShareDisabled,
                                              type: "checkbox",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.anecdotal.visibleStudent
                                              )
                                                ? _vm._i(
                                                    _vm.anecdotal
                                                      .visibleStudent,
                                                    null
                                                  ) > -1
                                                : _vm.anecdotal.visibleStudent,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.anecdotal
                                                      .visibleStudent,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.anecdotal,
                                                        "visibleStudent",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.anecdotal,
                                                        "visibleStudent",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.anecdotal,
                                                    "visibleStudent",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(" Students "),
                                          _c("span"),
                                        ]
                                      ),
                                      _c(
                                        "label",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          staticClass: "kt-checkbox",
                                          attrs: {
                                            title: _vm.anecdotal
                                              .guardianShareDisabled
                                              ? `Guardian sharing is ${
                                                  _vm.anecdotal.visibleGuardian
                                                    ? "always"
                                                    : "never"
                                                } enabled for this category`
                                              : "Share with guardians",
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.anecdotal.visibleGuardian,
                                                expression:
                                                  "anecdotal.visibleGuardian",
                                              },
                                            ],
                                            attrs: {
                                              disabled:
                                                _vm.anecdotal
                                                  .guardianShareDisabled,
                                              type: "checkbox",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.anecdotal.visibleGuardian
                                              )
                                                ? _vm._i(
                                                    _vm.anecdotal
                                                      .visibleGuardian,
                                                    null
                                                  ) > -1
                                                : _vm.anecdotal.visibleGuardian,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.anecdotal
                                                      .visibleGuardian,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.anecdotal,
                                                        "visibleGuardian",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.anecdotal,
                                                        "visibleGuardian",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.anecdotal,
                                                    "visibleGuardian",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(" Guardians "),
                                          _c("span"),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ],
            ],
            2
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__body" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "alert alert-light alert-elevate fade show" },
          [
            _c("div", { staticClass: "alert-icon" }, [
              _c("i", { staticClass: "flaticon-warning" }),
            ]),
            _c("div", { staticClass: "alert-text" }, [
              _vm._v(
                " An administrator must first define anecdotal categories to use this feature. "
              ),
            ]),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", {}, [
      _c("span", { staticClass: "kt-font-danger" }, [_vm._v("*")]),
      _vm._v(" Category"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass: "form-text text-muted",
        staticStyle: { "font-size": "0.8rem" },
      },
      [
        _c("i", { staticClass: "fab fa-markdown" }),
        _vm._v(" You can use "),
        _c(
          "a",
          { attrs: { target: "_blank", href: "https://commonmark.org/help/" } },
          [_vm._v(" Markdown ")]
        ),
        _vm._v(" to format your description. "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }