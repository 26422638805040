var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "kt-input-icon" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchTerm,
            expression: "searchTerm",
          },
        ],
        staticClass: "form-control pl-5",
        staticStyle: { background: "transparent !important" },
        attrs: { type: "text" },
        domProps: { value: _vm.searchTerm },
        on: {
          blur: function ($event) {
            return _vm.blurEventHandler($event)
          },
          focus: function ($event) {
            return _vm.focusEventHandler($event)
          },
          keyup: function ($event) {
            if (!$event.type.indexOf("key") && $event.keyCode !== 13)
              return null
            return _vm.selectPortfolio(0)
          },
          keydown: function ($event) {
            _vm.showPicker = true
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.searchTerm = $event.target.value
          },
        },
      }),
      _vm.onSelected
        ? _c(
            "span",
            { staticClass: "kt-input-icon__icon kt-input-icon__icon--left" },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm.selectedUser && !_vm.onSelected
        ? _c(
            "span",
            {
              staticClass: "kt-input-icon__icon kt-input-icon__icon--right",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  _vm.selectedUser = null
                  _vm.searchTerm = ""
                },
              },
            },
            [_vm._m(1)]
          )
        : _vm._e(),
    ]),
    _vm.users.length > 0 && _vm.showPicker
      ? _c(
          "div",
          { staticClass: "kt-widget4 p-3 mt-2" },
          _vm._l(_vm.users, function (user, idx) {
            return _c(
              "div",
              {
                key: `${idx}_portfolio`,
                staticClass: "kt-widget4__item",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.selectPortfolio(idx)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "kt-widget4__pic kt-widget4__pic--pic" },
                  [
                    _c("UserAvatar", { attrs: { "avatar-user": user } }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "kt-badge kt-badge--lg kt-badge--success",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(user.lastName.substring(0, 1)) +
                              _vm._s(user.firstName.substring(0, 1)) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "kt-widget4__info" }, [
                  _c(
                    "a",
                    {
                      staticClass: "kt-widget4__username",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.selectPortfolio(idx)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(user.lastName) +
                          ", " +
                          _vm._s(user.firstName) +
                          " "
                      ),
                    ]
                  ),
                  user.portfolioType == "Student"
                    ? _c("p", { staticClass: "kt-widget4__text" }, [
                        _vm._v(
                          " " +
                            _vm._s(user.extStudentId.substring(0, 3)) +
                            "-" +
                            _vm._s(user.extStudentId.substring(3, 6)) +
                            "-" +
                            _vm._s(user.extStudentId.substring(6, 9)) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  user.portfolioType == "Teacher"
                    ? _c("p", { staticClass: "kt-widget4__text" }, [
                        _vm._v(" " + _vm._s(user.schoolEmail) + " "),
                      ])
                    : _vm._e(),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "la la-search" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "la la-close" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }