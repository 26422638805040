<template>
<div
    v-if="!loading"
    class="kt-portlet"
    :class="{'mb-0': createAnecdotal}"
>
    <div class="kt-portlet__head" @mousedown="dragMouseDown">
        <div class="kt-portlet__head-label">
            <h3
                v-if="createAnecdotal"
                class="kt-portlet__head-title"
            >
                Create Anecdotal Event
            </h3>
            <h3
                v-else
                class="kt-portlet__head-title"
            >
                Edit Anecdotal Event
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <a
                v-if="toggleSize"
                href="#"
                class="btn btn-sm btn-icon btn-clean btn-icon-md"
                @click.stop.prevent="toggleSize"
            >
                <i :class="['la', minimized ? 'la-angle-up' : 'la-angle-down' ]" />
            </a>
            <a
                href="#"
                class="btn btn-icon"
                @click.stop.prevent="closeModal"
            >
                <i class="la la-close" />
            </a>
        </div>
    </div>
    <div :class="[minimized ? 'd-none' : '']">
        <template v-if="anecdotalEventCategories.length == 0">
            <div class="kt-portlet__body">
                <div class="row">
                    <div class="alert alert-light alert-elevate fade show">
                        <div class="alert-icon">
                            <i class="flaticon-warning" />
                        </div>
                        <div class="alert-text">
                            An administrator must first define anecdotal categories to use this feature.
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="kt-portlet__body">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="w-100">
                            <label class=""><span class="kt-font-danger">*</span> Category</label>
                            <AnecdotalCategoryPicker
                                :current-category="anecdotal.anecdotalEventCategoryId ? anecdotal : null"
                                :on-selected="categorySelected"
                            />
                        </div>
                        <div class="w-100 row pt-4">
                            <label class="col-9 col-form-label">Current Date and Time</label>
                            <div class="col-3">
                                <span class="kt-switch kt-switch--success">
                                    <label>
                                        <input
                                            v-model="currentDateTime"
                                            type="checkbox"
                                        >
                                        <span />
                                    </label>
                                </span>
                            </div>
                        </div>
                        <b-collapse v-model="showDateTime">
                            <div class="w-100 pt-3 row">
                                <div class="col-6">
                                    <label class=""><span class="kt-font-danger">*</span> Date</label>
                                    <div class="input-group date">
                                        <b-form-datepicker
                                            v-model="anecdotal.anecdotalEventDate"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            reset-button
                                            drop-bottom
                                            class="form-control kt-input"
                                        />
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label class="">Time</label>
                                    <div class="input-group date">
                                        <b-form-timepicker
                                            v-model="anecdotal.anecdotalEventTime"
                                            placeholder=""
                                            class="form-control kt-input"
                                            reset-button
                                            now-button
                                        />
                                    </div>
                                </div>
                            </div>
                        </b-collapse>
                    </div>
                    <div class="col-lg-5">
                        <label title="Add students or teachers">
                            <span class="kt-font-danger">*</span>
                            Participants
                            <code
                                v-if="participants.length > 0"
                                class="ml-4 kt-font-success"
                            >
                                {{ participants.length }} added
                            </code>
                        </label>
                        <AnecdotalUserPicker :on-selected="userAdded" />
                        <div class="tab-content student-list p-3">
                            <div class="kt-widget4">
                                <div
                                    v-for="(user, idx) in participants"
                                    :key="`${idx}_anec_portfolio`"
                                    class="kt-widget4__item"
                                >
                                    <div class="kt-widget4__pic kt-widget4__pic--pic">
                                        <UserAvatar :avatar-user="user">
                                            <div class="kt-badge kt-badge--lg kt-badge--success">
                                                {{ user.lastName.substring(0,1) }}{{ user.firstName.substring(0,1) }}
                                            </div>
                                        </UserAvatar>
                                    </div>

                                    <div class="kt-widget4__info">
                                        <a
                                            href="#"
                                            class="kt-widget4__username"
                                            @click.stop.prevent="removeUserAtIndex(idx)"
                                        >
                                            {{ user.lastName }}, {{ user.firstName }}
                                        </a>
                                        <p
                                            v-if="user.portfolioType == 'Student'"
                                            class="kt-widget4__text"
                                        >
                                            {{ user.extStudentId.substring(0, 3) }}-{{
                                                user.extStudentId.substring(3, 6)
                                            }}-{{ user.extStudentId.substring(6, 9) }}
                                        </p>
                                        <p
                                            v-if="user.portfolioType == 'Teacher'"
                                            class="kt-widget4__text"
                                        >
                                            {{ user.schoolEmail }}
                                        </p>
                                    </div>
                                    <a
                                        href="#"
                                        @click.stop.prevent="removeUserAtIndex(idx)"
                                    >
                                        <i class="la la-close" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-lg-12">
                        <label>Anecdotal Description</label>
                        <v-md-editor
                            v-model="anecdotal.anecdotalEventDescription"
                            height="200px"
                            :right-toolbar="rightToolbar"
                            :left-toolbar="leftToolbar"
                            mode="edit"
                            class="v-md-editor"
                            @change="anecdotal.anecdotalEventDescriptionEdited = true"
                        />
                        <span
                            class="form-text text-muted"
                            style="font-size: 0.8rem"
                        >
                            <i class="fab fa-markdown" />
                            You can use
                            <a
                                target="_blank"
                                href="https://commonmark.org/help/"
                            >
                                Markdown
                            </a>
                            to format your description.
                        </span>
                    </div>
                    <div
                        v-if="!createAnecdotal && anecdotal.anecdotalEventDescription"
                        class="col-lg-12 pt-5"
                    >
                        <label class="pb-3">Anecdotal Description Preview</label>
                        <VueShowdown
                            :markdown="anecdotal.anecdotalEventDescription"
                            flavor="github"
                            :options="{ emoji: true }"
                        />
                    </div>
                </div>
            </div>

            <div class="kt-portlet__foot row pt-4 pb-4 pl-5 pr-5" :class="[minimized ? 'd-none' : '']">
                <div class="col-lg-4">
                    <div class="mt-1">
                        <button
                            type="button"
                            class="btn btn-brand btn-bold"
                            :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                            :disabled="saving"
                            @click.stop.prevent="saveAnecdotal"
                        >
                            Save Anecdotal
                        </button>

                        <span v-if="editAnecdotalEventId && canDeleteAnecdotal">
                            <button
                                v-if="anecdotal.deleted"
                                type="button"
                                class="btn btn-label-success btn-sm btn-bold ml-3"
                                :disabled="saving || deleting"
                                @click.stop.prevent="deleteAnecdotal(false)"
                            >
                                Restore
                            </button>
                            <button
                                v-else
                                type="button"
                                class="btn btn-label-danger btn-sm btn-bold ml-3"
                                :disabled="saving || deleting"
                                @click.stop.prevent="deleteAnecdotal(true)"
                            >
                                Delete
                            </button>
                        </span>
                    </div>
                </div>
                <div
                    :class="{'col-lg-8': anecdotal.isConfidential, 'col-lg-2': !anecdotal.isConfidential}"
                >
                    <label
                        title="Confidential anecdotals are only visible to you and will not be shared with other users."
                        class="kt-checkbox kt-checkbox--tick kt-checkbox--danger mt-3"
                    >
                        <input
                            v-model="anecdotal.isConfidential"
                            type="checkbox"
                        >
                        <template v-if="!anecdotal.isConfidential">
                            Confidential
                        </template>
                        <template v-else>
                            <em class="text-muted kt-font-danger">
                                Only you will be able to view this anecdotal.
                            </em>
                        </template>
                        <span />
                    </label>
                </div>
                <div
                    v-if="!anecdotal.isConfidential"
                    class="col-lg-6"
                >
                    <div class="form-group mb-0 pull-right">
                        <label>Share With:</label>
                        <div class="kt-checkbox-inline">
                            <label
                                v-b-tooltip.hover
                                :title="anecdotal.studentShareDisabled ?
                                    `Student sharing is ${anecdotal.visibleStudent ? 'always' : 'never'} enabled for this category`
                                    : 'Share with students'"
                                class="kt-checkbox"
                            >
                                <input
                                    v-model="anecdotal.visibleStudent"
                                    :disabled="anecdotal.studentShareDisabled"
                                    type="checkbox"
                                >
                                Students
                                <span />
                            </label>
                            <label
                                v-b-tooltip.hover
                                :title="anecdotal.guardianShareDisabled ?
                                    `Guardian sharing is ${anecdotal.visibleGuardian ? 'always' : 'never'} enabled for this category`
                                    : 'Share with guardians'"
                                class="kt-checkbox"
                            >
                                <input
                                    v-model="anecdotal.visibleGuardian"
                                    :disabled="anecdotal.guardianShareDisabled"
                                    type="checkbox"
                                >
                                Guardians
                                <span />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</div>
</template>

<script>

import moment from 'moment';
// @ts-ignore
import { VueShowdown } from 'vue-showdown';
import { mapState } from 'vuex';
import Types from '../store/Types';
import AnecdotalCategoryPicker from '../rootComponents/anecdotals/AnecdotalCategoryPicker.vue';
import AnecdotalUserPicker from '../rootComponents/anecdotals/AnecdotalUserPicker.vue';
import UserAvatar from './UserAvatar.vue';

export default {
    name: 'EditCreateAnecdotalForm',
    components: {
        UserAvatar,
        AnecdotalUserPicker,
        AnecdotalCategoryPicker,
        VueShowdown,
    },
    props: {
        createAnecdotal: {
            type: Boolean,
            required: false,
            default: false,
        },
        createAnecdotalProperties: {
            type: Object,
            required: false,
            default() {
                return null;
            },
        },
        editAnecdotalEventId: {
            type: [String, Number],
            required: false,
            default: 0,
        },
        dragMouseDown: {
            type: Function,
            required: false,
            default: () => {},
        },
        toggleSize: {
            type: Function,
            required: false,
            default: null,
        },
        minimized: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            currentDateTime: true,
            anecdotal: {
                anecdotalEventId: 0,
                anecdotalEventCategoryId: null,
                anecdotalEventCategoryTitle: null,
                pointValue: null,
                anecdotalEventDate: null,
                anecdotalEventTime: null,
                anecdotalEventDescription: '',
                anecdotalEventDescriptionEdited: false,
                categoryColor: null,
                categoryIcon: null,
                students: [],
                teachers: [],
                visibleGuardian: false,
                isConfidential: false,
                visibleStudent: false,
                guardianShareDisabled: false,
                studentShareDisabled: false,
                deleted: false,
            },
            saving: false,
            deleting: false,
            leftToolbar: 'h bold italic strikethrough quote | ul ol table hr | link image',
            rightToolbar: 'preview',
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            showCreateAnecdotalPanel: (state) => state.createAnecdotalPanel.open,
            anecdotalEventCategories: (state) => state.database.anecdotalEventCategories,
            anecdotalEvents: (state) => state.database.anecdotalEvents,
        }),
        participants() {
            const { teachers, students } = this.anecdotal;
            const participants = [...students, ...teachers]
                .filter((u) => u)
                .sort((a, b) => ((`${a.lastName}${a.firstName}` > `${b.lastName}${b.firstName}`) ? 1 : -1));

            return participants;
        },
        loading() {
            if (this.createAnecdotal) {
                return false;
            } // edit
            return Boolean(!this.editAnecdotalEvent);
        },
        editAnecdotalEvent() {
            if (!this.editAnecdotalEventId) return null;
            const anecdotalEventId = this.editAnecdotalEventId;
            return this.anecdotalEvents.find((evt) => evt.anecdotalEventId == anecdotalEventId) || null;
        },
        canDeleteAnecdotal() {
            const { editAnecdotalEvent, user } = this;
            const { authorId } = editAnecdotalEvent;
            const userId = user.mappedUserId || user.userId;
            const { role } = user.school;
            return ['School Admin'].includes(role) || authorId == userId;
        },
        showDateTime: {
            get() {
                return !this.currentDateTime;
            },
            set() {},
        },
    },
    watch: {
        editAnecdotalEvent() {
            if (this.editAnecdotalEvent) {
                const { students, teachers } = this.$store.state.database;

                this.anecdotal = { ...this.anecdotal, ...this.editAnecdotalEvent };
                this.anecdotal.students = this.editAnecdotalEvent.students.map((s) => students.find((x) => x.studentEnrollmentId == s.studentEnrollmentId));
                this.anecdotal.teachers = this.editAnecdotalEvent.teachers.map((s) => teachers.find((x) => x.schoolStaffId == s.schoolStaffId));
            }
        },
    },
    mounted() {
        if (this.editAnecdotalEventId) {
            // editing existing anecdotal
            if (!this.editAnecdotalEvent) {
                // not in local cache so attempt to pull it from the server
                this.$store.dispatch(Types.actions.GET_ANECDOTAL_EVENT_BY_ID, { anecdotalEventId: this.$route.params.anecdotalEventId });
            } else {
                const { students, teachers } = this.$store.state.database;

                this.anecdotal.anecdotalEventId = this.editAnecdotalEvent.anecdotalEventId;
                this.anecdotal.anecdotalEventCategoryId = this.editAnecdotalEvent.anecdotalEventCategoryId;
                this.anecdotal.anecdotalEventCategoryTitle = this.editAnecdotalEvent.anecdotalEventCategoryTitle;
                this.anecdotal.anecdotalEventDate = this.editAnecdotalEvent.anecdotalEventDate;
                this.anecdotal.anecdotalEventTime = this.editAnecdotalEvent.anecdotalEventTime || null;
                this.anecdotal.anecdotalEventDescription = this.editAnecdotalEvent.anecdotalEventDescription || '';
                this.anecdotal.anecdotalEventDescriptionEdited = false;
                this.anecdotal.categoryColor = this.editAnecdotalEvent.categoryColor;
                this.anecdotal.categoryIcon = this.editAnecdotalEvent.categoryIcon;
                this.anecdotal.pointValue = this.editAnecdotalEvent.pointValue;

                this.anecdotal.students = this.editAnecdotalEvent.students.map((s) => students.find((x) => s.studentEnrollmentId == x.studentEnrollmentId));
                this.anecdotal.teachers = this.editAnecdotalEvent.teachers.map((s) => teachers.find((x) => s.schoolStaffId == x.schoolStaffId));

                this.anecdotal.visibleGuardian = this.editAnecdotalEvent.visibleGuardian;
                this.anecdotal.isConfidential = this.editAnecdotalEvent.isConfidential;
                this.anecdotal.visibleStudent = this.editAnecdotalEvent.visibleStudent;
                this.anecdotal.deleted = this.editAnecdotalEvent.deleted;
                this.categorySelected(this.editAnecdotalEvent);
                this.currentDateTime = false;
            }
        }

        // creating a new anecdotal
        if (this.createAnecdotal) {
            if (this.createAnecdotalProperties && this.createAnecdotalProperties.students) {
                const { students } = this.$store.state.database;
                this.anecdotal.students = this.createAnecdotalProperties.students.map((s) => {
                    const stu = students.find((x) => s.studentEnrollmentId == x.studentEnrollmentId);
                    return { ...stu };
                });
            }
            this.anecdotal.anecdotalEventDescriptionEdited = false;
            this.anecdotal.anecdotalEventDate = moment().format('YYYY-MM-DD');
        }
    },
    methods: {
        removeUserAtIndex(index) {
            const user = this.participants[index];
            if (user.studentEnrollmentId) {
                this.anecdotal.students = this.anecdotal.students.filter((s) => s.studentEnrollmentId != user.studentEnrollmentId);
            }
            if (user.schoolStaffId) {
                this.anecdotal.teachers = this.anecdotal.teachers.filter((t) => t.schoolStaffId != user.schoolStaffId);
            }
        },
        categorySelected(event) {
            const { anecdotalEventCategories } = this;
            if (!event) {
                this.anecdotal.anecdotalEventCategoryId = 0;
                this.anecdotal.anecdotalEventCategoryTitle = null;
                this.anecdotal.categoryColor = null;
                this.anecdotal.categoryIcon = null;
                this.anecdotal.pointValue = 0;
                this.anecdotal.guardianShareDisabled = false;
                this.anecdotal.studentShareDisabled = false;
                this.anecdotal.visibleGuardian = true;
                this.anecdotal.visibleStudent = true;
            } else {
                const category = anecdotalEventCategories.find((c) => c.anecdotalEventCategoryId == event.anecdotalEventCategoryId) || null;
                this.anecdotal.anecdotalEventCategoryId = category ? category.anecdotalEventCategoryId : 0;
                this.anecdotal.anecdotalEventCategoryTitle = category ? category.anecdotalEventCategoryTitle : null;
                this.anecdotal.categoryColor = category ? category.categoryColor : null;
                this.anecdotal.categoryIcon = category ? category.categoryIcon : null;
                this.anecdotal.pointValue = category ? category.pointValue : 0;
                this.anecdotal.guardianShareDisabled = category ? category.defaultGuardianShare === 'Never' : false;
                this.anecdotal.studentShareDisabled = category ? category.defaultStudentShare === 'Never' : false;
                if (category && category.defaultGuardianShare == 'Always') this.anecdotal.visibleGuardian = true;
                if (category && category.defaultGuardianShare == 'Never') this.anecdotal.visibleGuardian = false;
                if (category && category.defaultStudentShare == 'Always') this.anecdotal.visibleStudent = true;
                if (category && category.defaultStudentShare == 'Never') this.anecdotal.visibleStudent = false;

                // not edited this session AND its a new anec
                if (!this.anecdotal.anecdotalEventDescriptionEdited && !this.editAnecdotalEventId) {
                    this.anecdotal.anecdotalEventDescription = category ? category.defaultDescription || '' : '';
                }
            }
        },
        userAdded(user) {
            const { studentEnrollmentId, schoolStaffId } = user;
            const { teachers, students } = this.anecdotal;
            if (studentEnrollmentId) {
                const output = students.filter((s) => s && studentEnrollmentId !== s.studentEnrollmentId);
                output.push(user);
                this.anecdotal.students = [...output];
            } else if (schoolStaffId) {
                const output = teachers.filter((t) => t && schoolStaffId !== t.schoolStaffId);
                output.push(user);
                this.anecdotal.teachers = [...output];
            }
        },
        saveAnecdotal() {
            const v = this;
            if (v.saving) return;
            v.saving = true;

            const { anecdotal } = this;
            if (anecdotal.isConfidential) {
                anecdotal.visibleGuardian = false;
                anecdotal.visibleStudent = false;
            }

            if (anecdotal.students.length == 0) {
                v.saving = false;
                return this.showError('Please attach a student to this anecdotal');
            }
            if (!anecdotal.anecdotalEventCategoryId) {
                v.saving = false;
                return this.showError('Please select a category');
            }

            if (this.createAnecdotal) {
                this.$store.dispatch(Types.actions.CREATE_ANECDOTAL, {
                    anecdotalEvent: anecdotal,
                    callback(err) {
                        v.saving = false;
                        if (err) return v.showAlert('Error Creating Anecdotal', null, 'danger');
                        v.showAlert('Anecdotal Event Logged', null, 'success');
                        v.closeModal();
                    },
                });
            } else {
                this.$store.dispatch(Types.actions.EDIT_ANECDOTAL, {
                    anecdotalEvent: anecdotal,
                    callback(err) {
                        v.saving = false;
                        if (err) {
                            console.error(err);
                            v.showAlert('Error Saving Anecdotal', null, 'danger');
                            return;
                        }
                        v.showAlert('Anecdotal Event Edited', null, 'success');
                        v.closeModal();
                    },
                });
            }
        },
        deleteAnecdotal(value) {
            const v = this;
            if (v.deleting || v.saving) return;
            v.deleting = true;
            const { anecdotal } = this;
            anecdotal.deleted = value;

            this.$store.dispatch(Types.actions.EDIT_ANECDOTAL, {
                anecdotalEvent: anecdotal,
                callback(err) {
                    v.deleting = false;
                    if (err) {
                        console.error(err);
                        v.showAlert('Error Saving Anecdotal', null, 'danger');
                        return;
                    }
                    v.showAlert('Anecdotal Event Deleted', null, 'success');
                    v.closeModal();
                },
            });
        },
        closeModal() {
            if (this.createAnecdotal) {
                this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_CLOSE);
                return;
            }
            this.$router.push({ name: 'AnecdotalLogs' });
        },
    },
};
</script>

<style scoped>
div.student-list {
    max-height: 150px;
    overflow-y: scroll;
    overflow-x: clip;
}

</style>
