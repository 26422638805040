<template>
<div>
    <div class="kt-input-icon">
        <input
            v-model="searchTerm"
            type="text"
            style="background: transparent !important;"
            class="form-control pl-5"
            @blur="blurEventHandler($event)"
            @focus="focusEventHandler($event)"
            @keyup.13="selectPortfolio(0)"
            @keydown="showPicker = true"
        >
        <span
            v-if="onSelected"
            class="kt-input-icon__icon kt-input-icon__icon--left"
        >
            <span>
                <i class="la la-search" />
            </span>
        </span>

        <span
            v-if="selectedUser && !onSelected"
            class="kt-input-icon__icon kt-input-icon__icon--right"
            @click.stop.prevent="selectedUser = null; searchTerm = ''"
        >
            <span>
                <i class="la la-close" />
            </span>
        </span>
    </div>

    <div
        v-if="users.length > 0 && showPicker"
        class="kt-widget4 p-3 mt-2"
    >
        <div
            v-for="(user, idx) in users"
            :key="`${idx}_portfolio`"
            class="kt-widget4__item"
            @click.stop.prevent="selectPortfolio(idx)"
        >
            <div class="kt-widget4__pic kt-widget4__pic--pic">
                <UserAvatar :avatar-user="user">
                    <div class="kt-badge kt-badge--lg kt-badge--success">
                        {{ user.lastName.substring(0,1) }}{{ user.firstName.substring(0,1) }}
                    </div>
                </UserAvatar>
            </div>

            <div class="kt-widget4__info">
                <a
                    href="#"
                    class="kt-widget4__username"
                    @click.stop.prevent="selectPortfolio(idx)"
                >
                    {{ user.lastName }}, {{ user.firstName }}
                </a>
                <p
                    v-if="user.portfolioType == 'Student'"
                    class="kt-widget4__text"
                >
                    {{ user.extStudentId.substring(0, 3) }}-{{
                        user.extStudentId.substring(3, 6)
                    }}-{{ user.extStudentId.substring(6, 9) }}
                </p>
                <p
                    v-if="user.portfolioType == 'Teacher'"
                    class="kt-widget4__text"
                >
                    {{ user.schoolEmail }}
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import UserAvatar from '../../components/UserAvatar.vue';
// import Types from '../../store/Types';

export default {
    name: 'AnecdotalUserPicker',
    components: {
        UserAvatar,
    },
    props: {
        onSelected: {
            type: Function,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            searchTerm: '',
            selectedUser: null,
            showPicker: false,
            timeout: null,
        };
    },
    computed: {
        ...mapState({
            studentPortfolios: (state) => state.database.students,
            teacherPortfolios: (state) => state.database.teachers,
        }),
        users() {
            const searchTerm = ('' || this.searchTerm).toLowerCase();
            const portfolios = [...this.studentPortfolios, ...this.teacherPortfolios];
            const output = portfolios.filter((t) => {
                const search = `${t.lastName}, ${t.firstName} ${t.extStudentId || ''} ${t.googleEmail || ''} ${t.schoolEmail || ''}`.toLowerCase();
                return (search.indexOf(searchTerm.toLowerCase()) > -1);
            });
            return output.slice(0, 10);
        },
    },
    methods: {
        blurEventHandler() {
            const v = this;
            v.timeout = setTimeout(() => {
                v.showPicker = false;
            }, 600);
        },
        focusEventHandler() {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.showPicker = true;
        },
        selectPortfolio(idx) {
            const user = { ...this.users[idx] };
            if (user) {
                this.selectedUser = user;
                this.showPicker = false;
                this.searchTerm = '';
                if (this.onSelected) {
                    this.onSelected(user);
                }
            }
        },
    },
};
</script>

<style scoped>
.kt-widget4 {
    position: absolute;
    width: 85%;
    z-index: 2;
    background: white;
    border: 1px solid #e2e5ec;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: clip;
}

div.kt-widget4__info {
    overflow-x: clip;
    width: 80%;
}
</style>
